import React from 'react'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import RoomInformation from '../components/RoomInformation'

const Procedures = () => (
        <Layout>
            <Helmet>
                <title>Appointments | Dr. Piera Taylor</title>
                <meta name='keywords' content='surgeon, ENT, paediatric, Sydney, doctor, Piera Taylor, 
                cleft palate, hearing issues, salivary management, grommets'/>
                <meta name='description' content="Dr. Piera Taylor, one of Sydney's leading Paediatric ENT Surgeons, specialises in salivary management, cleft palate and ENT conditions for children with diabilities and syndromes."/>
                <meta name='robots' content='index,follow'/>
            </Helmet>
            <section className='hero is-primary is-small'>
                <div className='hero-body'>
                    <div className='container'>
                        <div className='columns'>
                            <div className='column is-10 is-offset-1'>
                                <div className='section is-centered'>
                                    <h1 className='title'>
                                                        Appointments
                                    </h1>
                                    <h2 className='subtitle is-italic'>
                                        Please Call to Book an Appointment
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <RoomInformation />
            <section className='section padding-vertical-zero'>
                <div className='container padding-vertical-zero'>
                    <div className='section padding-vertical-zero'> 
                        <div className='columns'>
                            <div className = 'column is-10 is-offset-1'>
                                <div className='content'>
                                    <h2 className='is-bold'>How to Find Us</h2>
                                    <p>Dr. Taylor conducts her appointments at the Italian Forum Building in Leichardt.
                                    Parking is under the building and can be accessed from both Norton street and Balmain street. Parking is free for patients seeing Dr. Taylor.
                                    Dr. Taylor's rooms are on <span className='is-bold'>Level 1</span>, and are found through the tunnel behind the shops - please see below for a visual guide.</p>
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
            </section>
            <section>
                <div className ='çontainer padding-vertical-small'>
                    <div className='section padding-vertical-small'>
                        <div className='columns padding-vertical-small'>
                            <div className='column is-2 is-offset-2 has-text-centered'>
                                <img style={{height:'20rem', width:'auto'}} alt = 'entrance to Italian Forum Building' src='/img/walkthrough1.jpg'/>
                                <p className = 'is-italic'>Entrance to Italian Forum</p>
                            </div>
                            <div className='column is-4 has-text-centered'> 
                                <iframe style={{height:'20rem', maxWidth:'100%'}} alt = 'video of tunnel access' src="https://www.youtube.com/embed/Fzi3Kxe8zCU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen /> 
                                <p className = 'is-italic'>Left Turn to Access Tunnel</p>
                            </div>
                            <div className='column is-2 has-text-centered'>
                                <img style={{height:'20rem', width:'auto'}} alt="front view of Dr. Piera Taylor's office" src='/img/walkthrough3.jpg'/>
                                <p className = 'is-italic'>Dr. Taylor's Office</p>
                            </div>
                        </div>
                        <div className='columns padding-vertical-small'>
                            <div className='column is-4 is-offset-4 has-text-centered'>
                                <img style={{height:'15rem', width:'auto'}} alt = 'parking street access of Piera Taylors office' src='/img/parking-map.PNG'/>
                                <p className='is-italic'>Italian Forum Parking Entrances</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </Layout>
)

export default Procedures